<template>
    <div v-if="globalData.status" class="c-notification-banner is-active js-notification-banner">
        <span v-html="globalData.content"></span>

        <button
            class="c-notification-banner__dismiss js-notification-banner__dismiss"
            type="button"
            aria-label="Dismiss banner"
            @click="dismissBanner"></button>
    </div>
</template>

<script setup>
    const globalData = ref(null);
    const dismissBanner = () => {
        document.querySelector(".js-notification-banner").remove();
    };

    const getNotificationBannerData = async () => {
        const { data } = await useFetch(`/api/wp/components/notification-banner`);

        // Check if data is empty or undefined
        if (!data || !data.value || data.value.length === 0) {
            // console.log('No data found for the given route:', route.fullPath);

            throw createError({
                statusCode: 404,
                statusMessage: "Page Not Found",
            });
        }

        globalData.value = data.value;
    };

    await getNotificationBannerData();

    // console.log("Global Data: ", globalData.value);
</script>
